import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { toggleMute, toggleNoiseSuppression, toggleEchoCancellation } from '../../../store/audioSlice';
import Slider from '../../inputs/slider';
import Toggle from '../../inputs/toggle';
import './user-settings-ses.scoped.css';

const UserSettingsAudio: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const isMuted = useSelector((state: AppState) => state.entities.audio.isMuted);
  const noiseSuppression = useSelector((state: AppState) => state.entities.audio.noiseSuppression);
  const echoCancellation = useSelector((state: AppState) => state.entities.audio.echoCancellation);

  const [inputDevices, setInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [outputDevices, setOutputDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedInput, setSelectedInput] = useState<string | null>(null);
  const [selectedOutput, setSelectedOutput] = useState<string | null>(null);
  const [outputVolume, setOutputVolume] = useState<number>(50);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        setInputDevices(devices.filter((device) => device.kind === 'audioinput'));
        setOutputDevices(devices.filter((device) => device.kind === 'audiooutput'));
      } catch (err) {
        console.error('Error loading devices:', err);
        setError('Cihazlar yüklenirken bir hata oluştu.');
      }
    };

    loadDevices();
  }, []);

  useEffect(() => {
    if (selectedInput) {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: { exact: selectedInput },
          },
        })
        .then((stream) => {
          const audioTrack = stream.getAudioTracks()[0];
          console.log('Selected input device updated');
        })
        .catch((err) => {
          console.error('Error selecting input device:', err);
          setError('Mikrofon seçimi başarısız oldu.');
        });
    }
  }, [selectedInput]);

  useEffect(() => {
    if (selectedOutput) {
      const audio = document.createElement('audio') as HTMLAudioElement & { setSinkId?: (id: string) => Promise<void> };

      if ('setSinkId' in audio) {
        audio
          .setSinkId!(selectedOutput)
          .then(() => console.log('Selected output device updated'))
          .catch((err) => {
            console.error('Error setting sink ID:', err);
            setError('Hoparlör seçimi başarısız oldu.');
          });
      } else {
        console.warn('setSinkId is not supported in this browser.');
        setError('Tarayıcınız hoparlör seçimini desteklemiyor.');
      }
    }
  }, [selectedOutput]);

  useEffect(() => {
    if (noiseSuppression || echoCancellation) {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            noiseSuppression,
            echoCancellation,
          },
        })
        .then(() => {
          console.log('Noise suppression or echo cancellation updated');
        })
        .catch((err) => {
          console.error('Error updating audio settings:', err);
          setError('Gelişmiş ayarlar güncellenirken bir hata oluştu.');
        });
    }
  }, [noiseSuppression, echoCancellation]);

  const saveSettings = async () => {
    const settings = {
      isMuted,
      noiseSuppression,
      echoCancellation,
      selectedInput,
      selectedOutput,
      outputVolume,
    };

    try {
      const response = await fetch('/api/settings/audio', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(settings),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Settings saved to backend');
      setError(null);
    } catch (err) {
      console.error('Error saving settings:', err);
      setError('Ayarlar kaydedilirken bir hata oluştu.');
    }
  };

  return (
    <div className="flex flex-col pt-14 px-10 pb-20 h-full">
      <header>
        <h1 className="text-xl font-bold">Ses & Mikrofon Ayarları</h1>
      </header>

      {error && <div className="text-red-500 mt-3">{error}</div>}

      <section className="mt-5">
        <h2 className="text-lg font-semibold">Ses Cihazları</h2>
        <div className="mt-3">
          <label className="block font-medium mb-1">Mikrofon</label>
          <select
            value={selectedInput || ''}
            onChange={(e) => setSelectedInput(e.target.value)}
            className="w-full p-2 border rounded"
          >
            {inputDevices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || 'Bilinmeyen Mikrofon'}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-3">
          <label className="block font-medium mb-1">Hoparlör</label>
          <select
            value={selectedOutput || ''}
            onChange={(e) => setSelectedOutput(e.target.value)}
            className="w-full p-2 border rounded"
          >
            {outputDevices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || 'Bilinmeyen Hoparlör'}
              </option>
            ))}
          </select>
        </div>
      </section>

      <section className="mt-5">
        <h2 className="text-lg font-semibold">Gelişmiş Ayarlar</h2>
        <div className="flex items-center mt-3">
          <Toggle
            id="mute-toggle"
            checked={isMuted}
            onChange={() => dispatch(toggleMute())}
          />
          <label htmlFor="mute-toggle" className="ml-3">
            Sessize Al
          </label>
        </div>
        <div className="flex items-center mt-3">
          <Toggle
            id="noise-suppression-toggle"
            checked={noiseSuppression}
            onChange={() => dispatch(toggleNoiseSuppression())}
          />
          <label htmlFor="noise-suppression-toggle" className="ml-3">
            Gürültü Engelleme
          </label>
        </div>
        <div className="flex items-center mt-3">
          <Toggle
            id="echo-cancellation-toggle"
            checked={echoCancellation}
            onChange={() => dispatch(toggleEchoCancellation())}
          />
          <label htmlFor="echo-cancellation-toggle" className="ml-3">
            Yankı Engelleme
          </label>
        </div>
      </section>

      <section className="mt-5">
        <h2 className="text-lg font-semibold">Çıkış Sesi</h2>
        <Slider
          min={0}
          max={100}
          step={1}
          value={outputVolume}
          onChange={(value) => setOutputVolume(value)}
          className="w-full"
        />
      </section>

      <button
        onClick={saveSettings}
        className="mt-5 bg-blue-500 text-white py-2 px-4 rounded"
      >
        Ayarları Kaydet
      </button>
    </div>
  );
};

export default UserSettingsAudio;
