import React from 'react';

interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  min?: number; // Minimum değeri opsiyonel hale getirdik
  max?: number; // Maksimum değeri opsiyonel hale getirdik
  step?: number; // Adım değerini ekledik
  className?: string; // CSS sınıfı
  ariaLabel?: string; // Erişilebilirlik için açıklama
}

const Slider: React.FC<SliderProps> = ({
  value,
  onChange,
  min = 0, // Varsayılan minimum değer
  max = 100, // Varsayılan maksimum değer
  step = 1, // Varsayılan adım
  className,
  ariaLabel,
}) => {
  return (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      className={className}
      aria-label={ariaLabel || 'Slider'}
    />
  );
};

export default Slider;
