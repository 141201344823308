import { useEffect } from 'react';
import PageWrapper from './page-wrapper';
import { ready } from '../../store/auth';
import { useDispatch } from 'react-redux';
import fetchEntities from '../../store/actions/fetch-entities';

const LoadingPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ready());
    dispatch(fetchEntities());
  }, []);

  const tips = [
    'LazCord.App Yükleniyor...🤔',
  ];
  const randomIndex = Math.floor(Math.random() * tips.length);

  return (
    <PageWrapper
      pageTitle="Yükleniyor..."
      className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-3xl">Yükleniyor...</h1>
        <p className="lead block">{tips[randomIndex]}</p>
      </div>
    </PageWrapper>
  );
}

export default LoadingPage;
