import { createSlice } from '@reduxjs/toolkit';

interface AudioState {
  noiseSuppression: boolean;
  echoCancellation: boolean;
  isMuted: boolean;
}

const initialState: AudioState = {
  noiseSuppression: false,
  echoCancellation: false,
  isMuted: false,
};

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    toggleNoiseSuppression(state) {
      state.noiseSuppression = !state.noiseSuppression;
    },
    toggleEchoCancellation(state) {
      state.echoCancellation = !state.echoCancellation;
    },
    toggleMute(state) {
      state.isMuted = !state.isMuted;
    },
  },
});

export const { toggleNoiseSuppression, toggleEchoCancellation, toggleMute } = audioSlice.actions;
export default audioSlice.reducer;
