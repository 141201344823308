import AgoraRTC from "agora-rtc-sdk-ng";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

export async function connectToVoiceChannel(
  appId: string,
  token: string,
  channelName: string,
  uid: string
) {
  try {
    await client.join(appId, token, channelName, uid);
    console.log(`Sesli kanala bağlanıldı: Kanal Adı - ${channelName}, UID - ${uid}`);
  } catch (error) {
    console.error("Sesli kanala bağlanırken hata:", error);
    throw error;
  }
}

export async function disconnectFromVoiceChannel() {
  try {
    await client.leave();
    console.log("Sesli kanaldan çıkış yapıldı.");
  } catch (error) {
    console.error("Sesli kanaldan çıkış sırasında hata:", error);
    throw error;
  }
}
