import './channel-tabs.scoped.css';
import { faHashtag, faVolumeUp, faCog, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import usePerms from '../../../hooks/use-perms';
import { getChannelUsers, joinVoiceChannel, deleteChannel } from '../../../store/channels';
import { getGuildChannels } from '../../../store/guilds';
import { actions as ui } from '../../../store/ui';
import FoundUsername from '../../user/username';
import React, { useState } from 'react';
import { Entity } from '@acrd/types';

const ChannelTabs: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { activeGuild, activeChannel } = useSelector((s: Store.AppState) => s.ui);
  const guildChannels = useSelector(getGuildChannels(activeGuild?.id));
  const textChannels = guildChannels.filter(c => c.type === 'TEXT');
  const voiceChannels = guildChannels.filter(c => c.type === 'VOICE');
  const perms = usePerms();

  if (!activeGuild || !perms.can('VIEW_CHANNELS', activeGuild.id)) return null;

  const handleDeleteChannel = (channelId: string) => {
    if (window.confirm('Bu kanalı silmek istediğinizden emin misiniz?')) {
      dispatch(deleteChannel(channelId)); // Redux aksiyonu ile kanalı sil
    }
  };

  const ChannelTab = ({ channel }: { channel: Entity.Channel }) => {
    const link = channel.type === 'VOICE' ? '#' : `/channels/${activeGuild!.id}/${channel.id}`;
    const icon = { TEXT: faHashtag, VOICE: faVolumeUp }[channel.type];
    const [isHovered, setIsHovered] = useState(false);

    const onClick = () => {
      if (channel.type === 'VOICE') {
        dispatch(joinVoiceChannel(channel.id));
      } else {
        dispatch(ui.pageSwitched({ channel, guild: activeGuild }));
      }
    };

    const VCMembers: React.FunctionComponent = () => {
      const users = useSelector(getChannelUsers(channel.id));
      if (channel.type !== 'VOICE' || !users.length) return null;

      return (
        <div className="p-2 pl-3">
          {users.map(user => (
            <ContextMenuTrigger key={user.id} id={user.id}>
              <div className="context-menu mb-1">
                <FoundUsername user={user} size="sm" guild={activeGuild} />
              </div>
            </ContextMenuTrigger>
          ))}
        </div>
      );
    };

    const ChannelTabContent: React.FunctionComponent = () => (
      <span
        className="tab flex-grow flex justify-between"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>{channel.name}</span>
        {isHovered && (
          <>
            {perms.can('MANAGE_CHANNELS', activeGuild.id) && (
              <FontAwesomeIcon
                className="settings cursor-pointer ml-2"
                icon={faCog}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(ui.pageSwitched({ channel, guild: activeGuild }));
                  dispatch(ui.openedModal('ChannelSettings'));
                }}
              />
            )}
            {perms.can('MANAGE_CHANNELS', activeGuild.id) && (
              <FontAwesomeIcon
                className="delete-channel cursor-pointer ml-2 text-red-500"
                icon={faTrash}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDeleteChannel(channel.id);
                }}
              />
            )}
          </>
        )}
      </span>
    );

    return (
      <>
        <ContextMenuTrigger key={channel.id} id={channel.id}>
          <div className="context-menu">
            <Link
              onClick={onClick}
              to={link}
              className={classNames(
                `cursor-pointer flex items-center rounded h-8 p-2 pl-3`,
                { active: channel.id === activeChannel?.id }
              )}
            >
              <FontAwesomeIcon
                size="xs"
                className={classNames(
                  `float-left scale-150 muted fill-current z-0`,
                  channel.type === 'VOICE' ? 'mr-2' : 'mr-3'
                )}
                icon={icon}
              />
              <ChannelTabContent />
            </Link>
          </div>
        </ContextMenuTrigger>
        {channel.type === 'VOICE' && <VCMembers />}
      </>
    );
  };

  return (
    <div className="channel-tabs">
      {/* Metin Kanalları */}
      <div>
        <h3 className="channel-group-title">Metin Kanalları</h3>
        {textChannels.map(c => (
          <ChannelTab key={c.id} channel={c} />
        ))}
      </div>

      {/* Ses Kanalları */}
      <div>
        <h3 className="channel-group-title">Ses Kanalları</h3>
        {voiceChannels.map(c => (
          <ChannelTab key={c.id} channel={c} />
        ))}
      </div>
    </div>
  );
};

export default ChannelTabs;
