import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { deleteChannel, updateChannel } from '../../../store/channels';
import { openSaveChanges } from '../../../store/ui';
import Input from '../../inputs/input';
import NormalButton from '../../utils/buttons/normal-button';
import Category from '../../utils/category';
import SaveChanges from '../../utils/save-changes';

const ChannelSettingsOverview: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const channel = useSelector((s: Store.AppState) => s.ui.activeChannel)!;
  const { register, handleSubmit, setValue } = useForm();

  const onSave = (e) => {
    const onUpdate = (payload) => dispatch(updateChannel(channel.id, payload));
    handleSubmit(onUpdate)(e);
  };
  const onDelete = () => {
    const confirmation = window.confirm('Bu Sunucuyu silmek istediğine emin misin?');
    confirmation && dispatch(deleteChannel(channel.id));
  }

  return (
    <form
      onChange={() => dispatch(openSaveChanges(true))}
      className="flex flex-col pt-14 px-10 pb-20 h-full mt-1">
      <header>
        <h1 className="text-xl font-bold inline">Kanala Genel Bakış</h1>
      </header>

      <section className="w-1/3">
        <Input
          label="İsim"
          name="name"
          register={register}
          options={{ value: channel.name }}
          className="pt-5" />
        <Input
          label="Özet"
          name="summary"
          register={register}
          options={{ value: channel.summary }}
          className="pt-5" />
        <Input
          tooltip='Mesajlardaki açık kelimeleri maskeleyin.'
          label="Küfür Filtreleme"
          name="filterProfanity"
          type="checkbox"
          register={register}
          options={{ value: channel.filterProfanity }}
          className="pt-5" />
      </section>

      <Category
        className="py-2 mt-5"
        title="Gelişmiş Ayarlar" />

      <section>
        <NormalButton
          type="button"
          onClick={onDelete}
          className="bg-danger">Kanalı Sil</NormalButton>
      </section>

      <SaveChanges
        setValue={setValue}
        onSave={onSave}
        obj={channel} />
    </form>
  );
}

export default ChannelSettingsOverview;