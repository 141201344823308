export async function fetchAgoraToken(channelId: string, uid: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/channels/${channelId}/join`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error("fetchAgoraToken Hata Yanıtı:", errorData);
      throw new Error("Token alınırken bir hata oluştu");
    }

    const data = await response.json();
    return data; // Token ve diğer gerekli veriler
  } catch (error) {
    console.error("fetchAgoraToken Hatası:", error);
    throw error;
  }
}

export async function fetchRooms() {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rooms`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Odalar alınırken bir hata oluştu");
    }

    const data = await response.json();
    return data.rooms; // Backend'den dönen odalar dizisi
  } catch (error) {
    console.error("fetchRooms Hatası:", error);
    throw error;
  }
}
